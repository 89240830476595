import "@cloudscape-design/global-styles/index.css";
import "./App.css";
import { useState, useEffect, useRef } from "react";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Sockette from "sockette";
import StartPage from "./components/startPage";
import CaptureImage from "./components/captureImage";
import AppLayout from "@cloudscape-design/components/app-layout";
import Box from "@cloudscape-design/components/box";
import StatusIndicator from '@cloudscape-design/components/status-indicator';
let ws = null;

const {ENDPOINT} = window.config[window.location.hostname];

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  /*global atob*/
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  /*global Blob*/
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

function App() {
  const [step, setStep] = useState("start");
  const [registration, setRegistration] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [uploadUrl, setUploadUrl] = useState("");
  const [uploadKey, setUploadKey] = useState("");
  const [statusType, setStatusType] = useState('pending');
  const [statusMessage, setStatusMessage] = useState('Connecting...');

  useEffect(() => {
    const messageHandler = (m) => {
      console.log("Message", m);
      const data = JSON.parse(m.data);
      if (data.action === "IMAGE_REQUEST") {
        setStep("capture");
        setUploadUrl(data.url);
        setUploadKey(data.key);
      }
    };
    const onOpen = (e) => {
      console.log("connected:", e);
      localStorage.setItem("registration", registration);
      setStatusType('success');
      setStatusMessage('Connected');
    };
    const onError = (e) => {
      console.log("ws error:", e);
      localStorage.removeItem("registration");
      setStatusType('error');
      setStatusMessage('Error');
    };
    const onClose = (e) => {
      console.log("Closed!", e);
      setStatusType('pending');
      setStatusMessage('Reconnecting...');
    }
    if (loggedIn) {
      ws = new Sockette(ENDPOINT + btoa(registration), {
        timeout: 5e3,
        onopen: (e) => onOpen(e),
        onmessage: (e) => messageHandler(e),
        onreconnect: (e) => console.log("Reconnecting...", e),
        onmaximum: (e) => console.log("Stop Attempting!", e),
        onclose: (e) => onClose(e),
        onerror: (e) => onError(e),
      });
      return function cleanup() {
        ws && ws.close();
        ws = null;
      };
    }
  }, [loggedIn, registration]);

  const login = (reg) => {
    setRegistration(reg);
    setLoggedIn(true);
    setStep("waiting");
  };

  const uploadImage = async (image) => {
    // Upload to presigned URL
    const headers = new Headers({ "Content-Type": "image/*" });
    const response = await fetch(uploadUrl, {
      method: "PUT",
      headers: headers,
      body: dataURItoBlob(image)
    });
    ws.json({ action: "IMAGE_UPLOADED", key: uploadKey});
    setStep("waiting");
  };

  let content = <p />;
  if (step === "start") {
    if (localStorage.getItem("registration")) {
      login(localStorage.getItem("registration"));
    }
    let urlRegistration = window.location.hash.substring(1);
    if (/....-......-../.test(urlRegistration)) {
      login(urlRegistration);
    }
    content = <StartPage action={login} />;
  }
  if (step === "waiting") {
    content = <SpaceBetween size="m">
            <ValueWithLabel label="Status">
        <StatusIndicator type={statusType}>{statusMessage}</StatusIndicator>
      </ValueWithLabel>
      <p>Keep this page open. Once you request an image from your computer, your camera will open here.</p>
      </SpaceBetween>;
  }
  if (step === "capture") {
    content = <CaptureImage action={uploadImage} />;
  }
  /*
            <span>Take a picture</span>
          <Webcam audio={false} screenshotFormat="image/jpeg" minScreenshotWidth="1280" screenshotQuality="1" mirrored={true} videoConstraints={{
              width: 1280,
              height: 720,
              facingMode: { exact: "environment" }
            }} width="100%"/>
          <Button variant="primary">Capture</Button><br />
          <textarea cols="80">{log}</textarea>
    */

  return (
    <AppLayout
    contentType="dashboard"
    toolsHide={true}
    navigationHide={true}
    content={
      <ContentLayout
        header={
          <>
            <div className="workshopHeader">
              <h1><img
                src="bricks.png"
                alt="logo"
                width="29"
                height="29"
              /> Brick Builders Session</h1>
            </div>
          </>
        }
      >
        <SpaceBetween size="l">
          <Container>
          {content}
          </Container>
        </SpaceBetween>
      </ContentLayout>
    }

  ></AppLayout>

  );
}

export default App;
