import React from "react";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import Input from "@cloudscape-design/components/input";
import SpaceBetween from "@cloudscape-design/components/space-between";


function StartPage(props) {
    const [registration, setRegistration] = React.useState("");
    return (   
        <form onSubmit={e => {e.preventDefault();props.action(registration)}}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary">Submit</Button>
                </SpaceBetween>
              }
              header={<Header variant="h2">Start</Header>}
            >
                    <FormField
      description="Enter your registration key here"
      label="Registration Key"
    >
                  <Input
                    onChange={({ detail }) => setRegistration(detail.value)}
                    value={registration}
                    /> </FormField>

            </Form>
          </form>)
                  
        
}


export default StartPage;