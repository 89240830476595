import { useState, useRef } from "react";
import { SpaceBetween } from "@cloudscape-design/components";
import Button from "@cloudscape-design/components/button";
import Webcam from "react-webcam";
let screenshot = "";

function CaptureImage(props) {
  const [state, setState] = useState("capture");
  const webcamRef = useRef(null);
  const capture = () => {
    screenshot = webcamRef.current.getScreenshot();
    setState("review");
  }
  const send = () => {
    props.action(screenshot);
  }
  let content = <p />
  if(state === "capture") {
    content = <SpaceBetween size="m">
    <span>Take a picture</span>
    <Webcam
      audio={false}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      minScreenshotWidth="1080"
      screenshotQuality="1"
      mirrored={false}
      videoConstraints={{
        width: 1080,
        height: 1080,
        facingMode: { exact: "environment" }
      }}
      width="100%"
    />
    <Button variant="primary" onClick={capture}>Capture Image</Button>
  </SpaceBetween>
  }
  if(state === "review") {
    content = <div>
    <span>Take a picture</span>
    <img src={screenshot} alt="captured" width="100%"/>
    <Button variant="primary" onClick={send}>Send Image</Button>{ }
    <Button variant="secondary" onClick={()=>{setState("capture")}}>Retry</Button>
  </div>
  }
  return content;
}

export default CaptureImage;
